<template>
  <div class="two-auth-box">
    <div class="box-title">
      <div class="text">Two Factor Authentication</div>
    </div>
    <div class="content-box">
      <b-row>
        <b-col md="6" lg="5" cols="12" class="text-center mb-4" v-if="!UserAuth.enable">
          <img
            :src="UserAuth.qr"
            alt=""
            style="filter: drop-shadow(2px 4px 6px #ccc)"
            class="mx-auto"
          />
        </b-col>
        <b-col md="6" lg="7" cols="12" class="mx-auto">
          <div class="form-group" v-if="!UserAuth.enable">
            <label for="">Authenticator Secret Code:</label>
            <div class="auth-copy">
              <b-input-group class="">
                <template #prepend>
                  <b-input-group-text>
                    <b-button
                      variant="secondary"
                      v-clipboard:copy="UserAuth.secret"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      >Copy</b-button
                    >
                  </b-input-group-text>
                </template>
                <b-form-input
                  type="text"
                  required
                  v-model="UserAuth.secret"
                  readonly
                ></b-form-input>
              </b-input-group>
            </div>
          </div>
          <div class="form-group">
            <label for="">Enter the verification code provided by your authentication app:</label>
            <div class="auth-copy">
              <b-form-input
                type="text"
                required
                v-model="authcode"
                placeholder="Enter Code Here"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group d-flex justify-content-center">
            <b-button class="mx-auto btn-egg mt-3" variant="success" @click="onUpdateAuth">{{
              UserAuth.enable ? 'Disable' : 'Enable'
            }}</b-button>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      authcode: '',
    };
  },
  computed: {
    ...mapGetters({
      UserAuth: 'user/UserAuth',
    }),
  },
  methods: {
    onCopy() {
      this.$toastr.s('Copy  Success', 'Successfully');
    },
    onError() {
      this.$toastr.e('Copy  Fail,Please Try Again', 'Failed');
    },
    onUpdateAuth() {
      this.$store.dispatch('user/req_postAuth', {
        authCode: this.authcode,
        secret: this.UserAuth.secret,
      });
      this.authcode = '';
    },
  },
  created() {
    this.$store.dispatch('user/req_getAuth');
  },
};
</script>

<style lang="scss">
.two-auth-box {
  width: 100%;
  min-height: 50px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 7px 10px 2px #00000038;
  position: relative;
  margin-bottom: 1.25rem;
  .box-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    padding: 0 1.25rem;
    background-color: #ff5c00;
    border-radius: 10px 10px 10px 10px;
    .text {
      color: #ffffff;
      font-weight: 600;
      font-size: 20px;
      text-transform: capitalize;
    }
    .icon {
      cursor: pointer;
      img {
        height: 20px;
        width: auto;
      }
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  .content-box {
    padding: 1.25rem;
    img {
      max-width: 100%;
    }
    .form-group {
      margin-bottom: 20px;
      label {
        font-size: 17px;
        color: #6c757d;
        font-weight: 600;
        margin-bottom: 5px;
      }
      .auth-copy {
        margin-top: 5px;
        background: #ff5c00;
        border-radius: 5px;
        .input-group-text {
          padding: 0;
          border: 0;
          button {
            width: 70px;
            height: 40px;
            padding: 0;
            // background: #8dc63f;
            border: 0;
            outline: none;
            border-radius: 5px;
            // box-shadow: 0px 0px 5px 1px #0000009e;
            font-weight: 600;
          }
        }
        input {
          height: 40px;
          border: 0;
          background: transparent;
          color: #fff;
          font-weight: 600;
          &::placeholder {
            color: #fff;
            opacity: 0.8;
          }
          &:hover,
          &:focus,
          &:focus-visible {
            outline: none;
            box-shadow: none;
            border: 0;
          }
        }
      }
    }
  }
}
</style>
